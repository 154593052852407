<template>
  <div class="jxyh-main">
    <fullscreen-container>
      <div
        :class="['jxyh-main-size']"
        :style="{
          background: `url(${require(`./assets/images/bg/${bgImageName}.webp`)})`,
          backgroundSize: '100% 100%',
        }"
      >
        <zy-page-header></zy-page-header>
        <div class="jxyh-main-body">
          <!-- 用于缓存含有iframe的路由 -->
          <component
            v-show="path == $route.path"
            :is="cachePath[path]"
            v-for="path in Object.keys(cachePath)"
            :key="path"
          />
          <keep-alive
            v-if="!$route.meta.unKeep"
            :include="/^cache-.*$/"
          >
            <router-view> </router-view>
          </keep-alive>
        </div>
      </div>
    </fullscreen-container>
  </div>
</template>

<script>
import ZyPageHeader from "./components/zy-page-header"
export default {
  components: {
    ZyPageHeader,
  },
  data() {
    return {
      bgImageName: "img_layout_bg",
      cachePath: {},
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        const meta = this.$route.meta
        if (meta.unKeep) {
          const path = val.path
          if (!this.cachePath[path]) {
            const matched = this.$route.matched
            const component = matched[matched.length - 1].components.default
            this.cachePath[path] = component
          }
        }
        document.title = meta.title
        console.log("12222")
        const projectId = val.query.projectId
        //如果对项目单独配置背景图，优先项目背景图
        if (meta[projectId]) {
          this.bgImageName = meta[projectId]
        } else {
          this.bgImageName = meta.bgName || "img_layout_bg"
        }
      },
    },
  },
  beforeCreate() {
    window.devicePixelRatio = 3
    document.title = this.$route.meta.title
    const projectId = this.$route.query.projectId
    this.$store.commit("SET_PROJECT_ID", projectId)
    this.$store.dispatch("INIT_PROJECT_DATA")
  },
  methods: {
    component() {
      const matched = this.$route.matched
      return matched[matched.length - 1].components.default
    },
  },
}
</script>

<style lang="less">
.jxyh-main {
  width: 100%;
  height: 100%;
  background: #11234b;
  overflow: hidden;
  box-sizing: border-box;

  &-size {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  &-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.jxyh-margin {
  margin-top: 20px;
}
.jxyh-flex-v {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jxyh-flex-h {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: SourceHanSansCN;
}
div {
  box-sizing: border-box;
}
</style>
